/* Styles the text that accompanies input */
label, 
.data-label {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    padding: 0.75em;
    max-width: 50vw;
    overflow-wrap: break-word;
}

/* Styles the label and input field */
div.input-group {
  display: flex;
  padding: 2%;
  min-width: 100%;
  justify-self: center;
}

/* Styles just the input field */
input[type="number"], 
input[type="text"] {
    width: 150px;
    min-width: 10%;
    max-width: 35%;
    padding: 0.75em;
    border-radius: 0.3em;
}

/* Makes sure the inputs are aligned */
div.input-group, p {
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-self: center;
}

/* Wraps everything */
.bce-container {
  min-width: 50%;
  max-width: max-content;
  text-align: center;
}

div.container, 
div.bce-container {
    display: flex;
    margin: 0 10%;
    height: 100vh;
    min-height: fit-content;
    max-width: 100vw;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    padding-top: 5%;
    padding-bottom: 3%;
}

/* Just the ending results page */
.bce-results-container {
  display: inline-block;
  min-width: max-content;
  max-width: max-content;
  align-items: center;
  text-align: center;
  overflow-y: auto;
  overflow-x: visible;
}

div.result-container {
  display: flex;
  flex-direction: column;
  min-width: max-content;
  max-width: 100%;
  margin-top: 20%;
}

/* Individual rider scores */
.bce-result {
    border: solid 1px black;
    background-color: lightgrey;
    padding: 5%;
    border-radius: 0.10em;
    
    min-width: fit-content;
    max-width: 100%;
    min-height: fit-content;

    display: inline-block;
    justify-items: center;
    align-self: center;
    flex-direction: column;
    margin: 10%;
}

/* For multi-buttons */
div.button-container {
  display: flex;
  margin: auto;
  padding: 1%;
}

/* same button stylings as for BC and CTR */
.action-button,
.submit-button, 
.go-back-button {
    display: block;
    width: 200px;
    padding: 15px;
    border: 2px solid black;
    background-color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: auto;
    text-align: center;
    align-self: center;
    justify-self: center;
}

/* Text effects */
/* p {
  width: max-content;
} */
h4,
h2,
h3,
h1,
p {
  display: flex;
  text-align: center;
  min-width: fit-content;
  justify-self: center;
  margin: 5%;
}

/* For desktop */
@media (min-width: 500px) {
  .bce-container {
    justify-content: center;
    align-items: center;
    width: 30vw;
    max-height: fit-content;
  }
  #root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* For mobile */
@media (max-width: 480px) {
  body {
    margin-bottom: 5%;
  }
}

/* Loading icon styling */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(128, 128, 128, 0.7); /* Gray semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(255, 255, 255, 0.5);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .error-message {
    color: red;
    margin-top: 20px;
    text-align: center;
  }
  